<template>
  <div class="paa-widget">
    <form-wizard
      class="paa-widget--formwizard"
      color="rgba(var(--vs-primary), 1)"
      title=""
      subtitle=""
      :startIndex="currentStep"
      :nextButtonText="$t('vue.next')"
      :backButtonText="$t('vue.back')"
      :finishButtonText="$t('inputs.submit')"
      ref="vegaFormWizard"
      shape="circle"
      @on-complete="onComplete"
      @on-change="onStepChanged"
    >
      <!-- tab 1 content -->
      <tab-content :title="$t('vega.tabs.howTo')" class="mb-5" icon="material-icons icon-question-mark" :lazy="true">
        <HowTo v-if="currentStep == 0" />
      </tab-content>

      <!-- tab 2 content -->
      <tab-content
        :title="$t('vega.tabs.chooseWidget')"
        class="tab2 mb-5"
        icon="material-icons icon-widgets"
        :before-change="validateChooseWidget"
        :lazy="true"
      >
        <ChooseWidget v-if="currentStep == 1" />
      </tab-content>

      <!-- tab 3 content -->
      <tab-content :title="$t('vega.tabs.popupText')" class="mb-5" icon="material-icons icon-title" :before-change="validateVegaPopup">
        <PopupText v-if="currentStep == 2" />
      </tab-content>
    </form-wizard>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'

import HowTo from './steps/HowTo.vue'
import ChooseWidget from './steps/ChooseWidget.vue'
import PopupText from './steps/PopupText.vue'

export default {
  name: 'AddVegaToWidget',
  components: {
    FormWizard,
    TabContent,
    HowTo,
    ChooseWidget,
    PopupText
  },
  data() {
    return {
      currentStep: 0,
      howToVideo: 'https://storage.googleapis.com/pathadvice-stage.appspot.com/vega/vega.mp4'
    }
  },
  props: {
    step: {
      type: Number,
      default: 0
    }
  },
  computed: {
    ...mapGetters({
      company: 'company',
      activeUserInfo: 'activeUser',
      selectedVegaWidgetId: 'selectedVegaWidgetId',
      selectedVegaWidget: 'selectedVegaWidget',
      selectedVegaWidgetPopupId: 'selectedVegaWidgetPopupId',
      vegaPopupThreshold: 'vegaPopupThreshold',
      vegaPopupName: 'vegaPopupName',
      vegaPopupText: 'vegaPopupText',
      vegaPopupMessage: 'vegaPopupMessage',
      vegaPopupPosition: 'vegaPopupPosition',
      isVegaPopupEnable: 'isVegaPopupEnable',
      isVegaPopupWithoutImage: 'isVegaPopupWithoutImage',
      isVegaPopupRequestOptionsDisabled: 'isVegaPopupRequestOptionsDisabled',
      vegaLockingTime: 'vegaLockingTime',
      hidePopupAfterSeconds: 'hidePopupAfterSeconds',
      selectedLanguageForVegaPopup: 'vegaPopupTranslation/selectedLanguageForVegaPopup',
      selectedVegaPopupTranslation: 'vegaPopupTranslation/selectedVegaPopupTranslation',
      hasVegaPopupTranslationUpdated: 'vegaPopupTranslation/hasVegaPopupTranslationUpdated'
    })
  },
  created() {
    if (this.step) {
      this.currentStep = this.step
    }
  },
  mounted() {
    const wizard = this.$refs.vegaFormWizard
    wizard.activateAll()
  },
  methods: {
    ...mapActions({
      setHasVegaPopupTranslationUpdated: 'vegaPopupTranslation/setHasVegaPopupTranslationUpdated'
    }),
    async onStepChanged() {
      setTimeout(() => {
        if (this.$refs && this.$refs.vegaFormWizard) {
          this.currentStep = this.$refs.vegaFormWizard.activeTabIndex
        }
      }, 300)
    },
    async saveTranslation() {
      if (this.selectedLanguageForVegaPopup && this.selectedLanguageForVegaPopup.code && this.selectedVegaWidgetPopupId && this.company && this.company.id) {
        const _update = {
          text: this.selectedVegaPopupTranslation.text || this.vegaPopupText,
          message: this.selectedVegaPopupTranslation.message || this.vegaPopupMessage,
          modified: new Date()
        }
        await this.$db
          .collection('translations')
          .doc(this.company.id)
          .collection('vega-popups')
          .doc(this.selectedVegaWidgetPopupId)
          .set({ companyId: this.company.id, updated: new Date() }, { merge: true })

        await this.$db
          .collection('translations')
          .doc(this.company.id)
          .collection('vega-popups')
          .doc(this.selectedVegaWidgetPopupId)
          .collection('languages')
          .doc(this.selectedLanguageForVegaPopup.code)
          .set(_update, { merge: true })

        /* As the translation is saved to firestore, set the below store prop to false */
        this.setHasVegaPopupTranslationUpdated(false)
      }
    },
    async onComplete() {
      try {
        if (
          this.activeUserInfo &&
          this.activeUserInfo.company &&
          this.vegaPopupName &&
          this.vegaPopupText &&
          this.vegaPopupMessage &&
          this.selectedVegaWidgetId
        ) {
          await this.$vs.loading()
          const vega = {
            company: this.activeUserInfo.company,
            name: this.vegaPopupName,
            text: this.vegaPopupText,
            message: this.vegaPopupMessage,
            position: this.vegaPopupPosition,
            vegaLockingTime: Number(this.vegaLockingTime),
            hidePopupAfterSeconds: Number(this.hidePopupAfterSeconds),
            isVegaPopupWithoutImage: this.isVegaPopupWithoutImage,
            isVegaPopupRequestOptionsDisabled: this.isVegaPopupRequestOptionsDisabled,
            isVegaWidget: true,
            isVegaWidgetEnabled: true,
            widgetId: this.selectedVegaWidgetId
          }
          if (this.vegaPopupThreshold) {
            vega.vegaPopupThreshold = this.vegaPopupThreshold
          }
          if (!this.isVegaPopupEnable) {
            vega.isVegaPopupEnable = false
          }
          if (!this.selectedVegaWidgetPopupId) {
            vega.created = new Date()
            await this.$db.collection('vega-popups').add(vega)
          } else {
            vega.updated = new Date()
            await this.$db.collection('vega-popups').doc(this.selectedVegaWidgetPopupId).set(vega)
          }
          await this.saveTranslation()
          await this.$vs.loading.close()
          this.$emit('on-complete')
        }
      } catch (error) {
        await this.$vs.loading.close()
        console.log(error.message)
      }
    },
    validateChooseWidget() {
      if (this.selectedVegaWidget && this.selectedVegaWidgetId) {
        return true
      }
      return false
    },
    validateVegaPopup() {
      if (this.vegaPopupName && this.vegaPopupText && this.vegaPopupMessage && this.selectedVegaWidgetId) {
        return true
      }
      return false
    }
  }
}
</script>
<style lang="scss">
.icon-question-mark::before {
  content: 'question_mark';
}
.icon-title::before {
  content: 'title';
}
.icon-widgets::before {
  content: 'widgets';
}

.paa-widget {
  width: 100vw;
  height: calc(100vh - 65px);
  position: relative;

  @media (min-width: 1200px) {
    width: calc(100vw - 325px) !important;
  }

  height: 100%;
  overflow-y: auto;
  margin-top: 24px;

  background: #fff;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  padding: 0px 21px;

  // &--formwizard {
  //   max-width: 2118px;
  // }

  // .wizard-nav-pills {
  //   max-width: 1118px;
  // }

  &--formwizard .wizard-tab-content {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 20px 10px;
  }
}
</style>
